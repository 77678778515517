import React, {useState} from 'react'
import ReactPlayer from 'react-player';

//Showreel Play Button
import CrossButton from "../assets/images/crossicon.png"

const HomeVideoBlock = (props) => {
const [isPlay, setIsPlay] = useState(false);
const [isPlayPopup, setIsPlayPopup] = useState(false);

  return (
      <>
        <div className="video-block showreel" data-sal="slide-up" id={props.ID} data-hover="Watch Showreel">
            <video 
                // src={Video}
                src="https://backend.decojent.com/wp-content/uploads/2023/04/ShowreelWebsite.mp4" 
                muted 
                autoPlay 
                loop
                playsInline
                style={{width: "100%", display: "block"}}
                onClick={() => {
                    setIsPlay(!isPlay)
                    setIsPlayPopup(true)
                }}
            > 
            </video>
           {/* <ReactPlayer
               className='react-player'
               id="video"
               url={Video}
               light={Thumbnail}
               playing={true}
               loop={true} 
               muted={true}
               playsinline={true}
           /> */}
           {/* <div className="play-btn" 
           onClick={() => {
                setIsPlay(!isPlay)
                setIsPlayPopup(true)
            }}
           >
               <img 
                   src={play}
                   width={100}
                   alt="play button"
               />
           </div> */}
        </div>
        <div className={'video-popup ' + (isPlayPopup ? "in-view" : "")}>
            <div className='close-icon'
                onClick={() => {
                    setIsPlayPopup(false)
                }}
            >
                <img src={CrossButton} alt="Stop Video" />
            </div>
            <ReactPlayer 
                id="video" 
                url='https://player.vimeo.com/video/774722972' 
                playing={isPlayPopup} 
                controls={true}
                onClick={() => {
                    setIsPlayPopup(!isPlayPopup)
                }}
            />
        </div>
    </>
  )
}

export default HomeVideoBlock